
import {
  defineAsyncComponent,
  defineComponent,
  onMounted,
  reactive,
} from "vue";
import { gpf } from "@/utils/global-functions";

const ModalTemplate = defineAsyncComponent(
  () => import(`@/components/modal-template/Main.vue`)
);

const servicesV1Public = async () => await import(`@/services/v1/public/index`);

export default defineComponent({
  name: "ModalUpdateProfile",
  components: {
    ModalTemplate,
  },
  setup() {
    // START --- action form for ubah password ------------------------------------------------
    const form = reactive({
      oldPin: "",
      newPin: "",
    });

    const emptyForm = () => {
      form.oldPin = "";
      form.newPin = "";
    };

    onMounted(() => {
      emptyForm();
    });

    const requestUbahPin = async () => {
      gpf.gLoading.show();

      const { resetPin } = await servicesV1Public();

      try {
        const payload = new URLSearchParams();
        payload.append("oldPin", form.oldPin);
        payload.append("newPin", form.newPin);

        await resetPin(payload);

        emptyForm();
        gpf.handleSdsV2("Ubah Pin Berhasil", "toast");
        gpf.hideModal("#modal-ubah-pin");

        gpf.gLoading.hide();
      } catch (e: any) {
        const err = e.response;
        console.log("err response ", err);

        gpf.handleEds(e);
        gpf.gLoading.hide();
      }
    };

    const visible = reactive({
      oldPin: false,
      newPin: false,
    });

    const onCancelClick = () => {
      emptyForm();
      visible.oldPin = false;
      visible.newPin = false;
      gpf.hideModal("#modal-ubah-pin");
    };
    // END --- action form for ubah password --------------------------------------------------

    return {
      gpf,
      form,
      requestUbahPin,
      visible,
      onCancelClick,
    };
  },
});
